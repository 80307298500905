<template>
    <div>
        <div class="g-tile-row">
            <div class="g-tile">
                <div class="num">{{info.totalWorkerNum}}</div>
                <div class="text">全部工人数</div>
            </div>
            <div class="g-tile">
                <div class="num">{{info.jobWorkerNum}}</div>
                <div class="text">工作中</div>
            </div>
            <div class="g-tile">
                <div class="num num-idle">{{info.freeWorkerNum}}</div>
                <div class="text">空闲中</div>
            </div>
        </div>
        
        <div class="my-flex-row">
            <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">全部</div>
            <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">工作中</div>
            <div class="filter-botton" :class="{active: tabIndex == 2}" @click="tabIndex = 2">空闲中</div>
            <div class="filter-botton" :class="{active: tabIndex == 3}" @click="tabIndex = 3">今日工时排名</div>
            <div class="filter-botton" :class="{active: tabIndex == 4}" @click="tabIndex = 4">本月工时排名</div>
        </div>
        <info-table style="margin: 0 10px 20px;" :showHeader="true" :columns="table.columns" :data="tableList" @rowClick="onRowClick"></info-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import infoTable from '@/components/InfoTable'
import {getWorkerOrderRank} from '@/axios/device'
export default {
    components: {infoTable},
    data(){
        return {
            tabIndex: 0,
            table: {
                columns: [{name: "userName", text: "姓名"}, {name: "workStatus", text: "状态", formatter: function(_v){
                    if(_v == 0){
                        return `<span style="color: #8c8c8c;background: #ebebeb;border-radius: 12px;padding: 4px 10px;">空闲</span>`
                    }else{
                        return `<span style="color: #ffffff;background: #3f83f8;border-radius: 12px;padding: 4px 10px;">工作</span>`
                    }
                }}, {name: "equipDesc", text: "正在调试设备"}, 
                {name: "dayWorkTime", text: "今日工时", formatter: function(_v){
                    return `${_v}H`
                }}, {name: "monthWorkTime", text: "本月工时", formatter: function(_v){
                    return `${_v}H`
                }}]
            },
            info: { workerVoList: []}
        }
    },
    computed: {
        ...mapGetters(['equipInfo']),
        tableList(){
            switch(parseInt(this.tabIndex)){
                case 1: return this.info.workerVoList.filter(_itm => _itm.workStatus == 1);
                case 2: return this.info.workerVoList.filter(_itm => _itm.workStatus == 0);
                case 3: 
                let _tmp = this.info.workerVoList || []
                return _tmp.sort(function(a, b){
                    return a.dayWorkTime > b.dayWorkTime
                });
                case 4: 
                let _tmp1 = this.info.workerVoList || []
                return _tmp1.sort(function(a, b){
                    return a.monthWorkTime > b.monthWorkTime
                });
                default: return this.info.workerVoList
            }
        }
    },
    created(){
        getWorkerOrderRank({
            equipType: this.equipInfo.equipType
        }).then(res => {
            this.info = res.content
            let _tab = this.info.workerVoList || []
            _tab.forEach(_item => {
                _item.equipDesc = _item.equipCode? (_item.equipCode + " " + _item.seqName) : "暂无"
            });
        })
    },
    methods: {
        onRowClick(_row){
            this.$router.push({path: "/home/workerAssign", query: {userName: _row.userName, userAccount: _row.userAccount}})
        }
    }
}
</script>

<style lang="scss" scoped>
    .g-tile-row{
        margin: 20px 0;
    }
    .my-flex-row{
        display: flex;
        margin: 10px;
        font-size: 12px;
        align-items: center;
        justify-content: space-around;
        .filter-botton{
            font-size: 12px;
            padding: .3rem .5rem;
        }
    }
</style>